import { useState, useEffect } from 'react';

import '../style.css';
import data from '../data.js';
import BattleMap from './BattleMap';
import Score from './Score';
import GameOver from './GameOver';

function App() {
  const [battleMap, setBattleMap] = useState(new Array(10).fill().map(() => new Array(10).fill(null)));
  const [battleState, setBattleState] = useState({});
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    initBattleState();
    addShipsOnBattleMap();
  }, []);

  const initBattleState = () => {
    const initState = data.shipTypes;
    Object.keys(initState).map((key) => initState[key].hits = 0);
    setBattleState(initState);
  }

  const addShipsOnBattleMap = () => {
    data.layout.forEach((shipData) => {
      shipData.positions.forEach((position) => {
        battleMap[position[1]][position[0]] = shipData.ship;
      })
    });

    setBattleMap([...battleMap]);
  }

  const attack = (rowIndex, cellIndex) => {
    const attackCell = battleMap[rowIndex][cellIndex];

    if (attackCell && 
        !gameOver && 
        battleState[attackCell] && 
        battleState[attackCell].hits < battleState[attackCell].size) {
      battleState[attackCell].hits++;
      battleMap[rowIndex][cellIndex] = { attack: 'hit' };
      setGameOver(isGameOver());
    } else if (!attackCell) {
      battleMap[rowIndex][cellIndex] = { attack: 'miss' };
    }

    setBattleState({...battleState});
  }

  const isGameOver = () => {
    for (const ship in battleState) {
      if (battleState[ship].hits !== battleState[ship].size) return false;
    }

    return true;
  }

  return (
    <>
      <div className="container mt-5 pb-5">
        <GameOver gameOver={gameOver} />
        <div className="row">
          <Score battleState={battleState} />
          <BattleMap 
            battleMap={battleMap}
            attack={attack}
          />
        </div>
      </div>
    </>
  );
}

export default App;
