function Score({ battleState }) {
    return (
        <div className="col-lg-4 col-md-12 col-sm-12 score">
            {Object.keys(battleState).map((element, index) => 
              <div key={index} className="w-100 ship-status">
                <img alt="" 
                  src={`${process.env.PUBLIC_URL}/images/${element}.png`} 
                  className="ship" />
                {new Array(battleState[element].hits)
                  .fill()
                  .map((element, index) => <img alt=""
                    key={index}
                    src={`${process.env.PUBLIC_URL}/images/hit.png`} 
                    className="hit" 
                />)}
              </div>
            )}
          </div>
    )
}

export default Score;