function GameOver({ gameOver }) {
    return (
        <>
            {gameOver &&
                <div className="alert alert-danger" role="alert">
                    You sunk all of the ships, the game is over!
                </div>
            }
        </>
    )
}

export default GameOver;