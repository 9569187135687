function BattleMap({ battleMap, attack }) {
    return (
        <div className="col-lg-8 col-md-12 col-sm-12">
            {battleMap.map((rows, rowIndex) => 
              <div key={rowIndex} 
                className="row">
                  {rows.map((cell, cellIndex) => 
                    <div key={cellIndex} 
                      className="col"
                      onClick={() => attack(rowIndex, cellIndex)}>
                      {battleMap[rowIndex][cellIndex] &&
                        <img alt="" 
                        src={`${process.env.PUBLIC_URL}/images/${battleMap[rowIndex][cellIndex].attack}.png`} 
                        className="cell-icon pt-1" />
                      }
                    </div>
                  )}
              </div>
            )}
        </div>
    )
}

export default BattleMap;